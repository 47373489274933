<template>
    <div>
      <b-card-text>
        <h5 class="mb-2">Como cualquier inversión, siempre existen riesgos, y en el caso de <strong style="color:#008D36">Sosty</strong> existen varias variables 
          que pueden hacer que un proyecto tenga cero ganancias e incluso pérdidas:
        </h5>
        <app-collapse accordion>
          <app-collapse-item title="Precio del ganado">
            La variación del precio del ganado en el mercado impacta directamente la rentabilidad de los proyectos, ya que nosotros no tenemos control de este, 
            y si el ganado se compra a un precio, pero meses después el precio del ganado cae, entonces al momento de la venta esto se podría traducir en cero 
            ganancia o pérdidas. En este <a href="https://www.fedegan.org.co/estadisticas/precios" target="_blank">link</a> encuentras los datos oficiales sobre la variación de precios del ganado.                 
          </app-collapse-item>
          <app-collapse-item title="La ganancia de peso">
            La ganancia de peso de los animales se estima basada en las condiciones de las fincas y en el promedio de engorde de nuestros otros proyectos, pero existen muchos factores que no controlamos que pueden afectar el desempeño de los animales, por ejemplo, podemos tener veranos o inviernos muy fuertes. También sucede que algunas veces a pesar de que seleccionamos los mejores animales, estos pueden enfermar o por cuestiones genéticas y corporales no tener el mejor desempeño.
          </app-collapse-item>
          <app-collapse-item title="Las muertes de animales">
            Las muertes de animales están cubiertas en la mayoría de los casos por nuestra póliza de seguro, la cual cubre gran parte de la pérdida, pero de todas formas si se presentan demasiadas muertes en un mismo proyecto esto al final puede impactar de forma negativa.
          </app-collapse-item>
          <app-collapse-item title="Eventos logísticos">
            Eventos logísticos en las fincas. En Sosty seleccionamos durante un proceso riguroso los mejores Ganaderos y Fincas en los cuales podemos ejecutar
              los proyectos, pero esto no nos exime que durante la ejecución puedan suceder acontecimientos que impactan negativamente las rentabilidades, ya 
              que no tenemos cómo prever el 100% de las cosas que pueden suceder en cada finca. Ejemplos de estos eventos pueden ser: <br>                
              <ul>
                <li>Malos manejos y monitoreo del ganado</li>
                <li>No reporta muertes a tiempo</li>
                <li>Sobrecargar las fincas con muchos animales</li>
                <li>Malas negociaciones de venta</li>
                <li>Negociaciones no cumplidas por parte de proveedores y compradores</li>
                <li>Percances personales del personal y el dueño de la finca</li>
                <li>Actitudes y acciones de mala fé</li>
                <li>Entre otros.</li>
              </ul>
          </app-collapse-item>
          <app-collapse-item title="Fechas de inicio y fín">
            <strong style="color:#008D36">Sosty</strong> propone fechas de inicio y fin para cada proyecto, pero esto solo son fechas tentativas no exactas, 
            ya que el precio del ganado puede variar mucho y no es conveniente salir a comprar o vender de forma inmediata, en estos casos se comunicará a los 
            Inversionistas y se les solicitará extensiones de tiempo. También algunas veces los proyectos pueden demorar más de lo esperado si la ganancia de 
            peso no ha sido la proyectada.
          </app-collapse-item>
          <app-collapse-item title="Animales de descarte">
            Algunas veces dentro de una manada de animales, hay algunos que tienen baja ganancia de peso, a pesar de que coman la misma cantidad y en el mismo 
            lugar de los otros. Estos animales con bajo rendimiento se denominan descartes y se deben vender anticipadamente para no afectar el rendimiento del 
            resto de la manada. 
          </app-collapse-item>
          <app-collapse-item title="Liquidaciones parciales">
            Algunas veces dentro de los proyectos, fincas o animales, pueden suceder eventos inesperados, que nos fuerzan a liquidar parcialmente los proyectos 
            y vender animales de forma prematura para no generar un mayor impacto. Esto puede afectar la rentabilidad al finalizar el proyecto. 
          </app-collapse-item>
          <app-collapse-item title="Tipo de ganadería">
            Aclarar que no aplicamos cualquier tipo de ganadería, que es regenerativa y nuestro objetivo es encontrar un balance entre rentabilidad y sostenibilidad 
            y esto eventualmente puede afectar las expectativas. 
          </app-collapse-item>
        </app-collapse>
      </b-card-text>
    </div>    
</template>

<script>

import {
  BCard, BCardText, BButton, BRow, BCol, } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  name:"RisksModalComponent",
  components: {        
      AppCollapse,
      AppCollapseItem,
      BCard,
      BCardText,
      BButton,        
      BRow,
      BCol,        
  },   
}


</script>