<template>
    <div>
    <b-row class="match-height">
        <b-col md="5" lg="5" >
            <b-carousel id="carousel-example-generic" controls indicators :interval="0">          
              <b-carousel-slide v-if="cattleOffering.projectVideoUrl1">
                <template #img>
                  <iframe :src="cattleOffering.projectVideoUrl1" class="sosty-project-details-video" frameborder="0" style="max-height:700px" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                  
                </template>
              </b-carousel-slide>
              <b-carousel-slide v-if="cattleOffering.projectVideoUrl2">
                <template #img>
                  <iframe :src="cattleOffering.projectVideoUrl2" class="sosty-project-details-video" frameborder="0" style="max-height:700px" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                  
                </template>
              </b-carousel-slide>
              <b-carousel-slide v-if="cattleOffering.projectImageUrl1">
                <template #img>
                  <b-img :src="cattleOffering.projectImageUrl1" class="sosty-project-details-image" fluid alt="Responsive image" style="max-height:700px" />
                </template>
              </b-carousel-slide>
              <b-carousel-slide v-if="cattleOffering.projectImageUrl2">
                <template #img>
                  <b-img :src="cattleOffering.projectImageUrl2" class="sosty-project-details-image" fluid alt="Responsive image" style="max-height:700px" />
                </template>
              </b-carousel-slide>
            </b-carousel>
        </b-col>
    
        <b-col md="7" lg="7">      
          <b-card >        
            <b-card-title>
              <b-row class="mb-0">
                <b-col>
                    <h3> #{{cattleOffering.cattleOfferCode}}</h3>
                    <h6 style="opacity:0.6">Ubicación: {{cattleOffering.locationCity}} </h6>
                </b-col>
                <b-col> 
                  <b-row style="align-items:center;">  
                    <b-col style="text-align:right;">
                        <h6 style="font-size:10px; color:#008D36;">{{cattleOffering.locationState}} - {{cattleOffering.locationCity}}
                            <b-avatar  size="18" variant="light-primary">
                                <feather-icon size="14" icon="MapPinIcon"/>
                            </b-avatar>  
                        </h6>                              
                    </b-col>
                  </b-row>
                  <b-row style="align-items:center;">  
                    <b-col style="text-align:right;">
                        <h6 style="font-size:10px; color:#008D36;">{{cattleOffering.offerStatus}}
                            <b-avatar  size="18" variant="light-primary">
                                <feather-icon size="14" icon="MapPinIcon"/>
                            </b-avatar>  
                        </h6>                              
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card-title>
    
          <b-card-body class="statistics-body" style="padding:0px 21px 10px 21px">
            <b-row class="mt-1">
                <b-col md="4" lg="4" sm="4" class="mb-2 mb-md-0 mb-2 mb-xl-0">
                  <b-media no-body>
                    <b-media-aside class="mr-2">
                        <b-avatar size="48" variant="light-primary">
                        <feather-icon size="24" icon="TagIcon"/>
                        </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                        <h4 class="font-weight-bolder mb-0">{{cattleOffering.amountOfAnimals}}</h4>
                        <b-card-text class="font-small-3 mb-0">Animales</b-card-text>
                    </b-media-body>
                </b-media>
                </b-col>
                <b-col md="4" lg="4" sm="4" class="mb-2 mb-md-0 mb-2 mb-xl-0 ">
                  <b-media no-body>
                      <b-media-aside class="mr-2">
                          <b-avatar size="48" variant="light-primary">
                          <feather-icon size="24" icon="BookmarkIcon"/>
                          </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                          <h4 class="font-weight-bolder mb-0">{{(cattleOffering.productType)}}</h4>
                          <b-card-text class="font-small-3 mb-0">Especie</b-card-text>
                      </b-media-body>
                  </b-media>
                </b-col>
                <b-col md="4" lg="4" sm="4" class="mb-2 mb-md-0 mb-2 mb-xl-0">
                  <b-media no-body>
                      <b-media-aside class="mr-2">
                          <b-avatar size="48" variant="light-primary">
                          <feather-icon size="24" icon="FolderIcon"/>
                          </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">                                    
                          <h5 class="font-weight-bolder mb-0">{{(cattleOffering.category)}} </h5>
                          <b-card-text class="font-small-3 mb-0">Categoria</b-card-text>
                      </b-media-body>
                  </b-media>
                </b-col>            
                <b-col md="4" lg="4" sm="4" class="mb-2 mb-md-0 mb-2 mb-xl-0 mt-1">
                  <b-media no-body>
                      <b-media-aside class="mr-2">
                          <b-avatar size="48" variant="light-primary">
                          <feather-icon size="24" icon="SlackIcon"/>
                          </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">                                    
                          <h4 class="font-weight-bolder mb-0">{{(cattleOffering.breed)}} </h4>
                          <b-card-text class="font-small-3 mb-0">Raza</b-card-text>
                      </b-media-body>
                  </b-media>
                </b-col> 
                <b-col md="4" lg="4" sm="4" class="mb-2 mb-md-0 mb-2 mb-xl-0 mt-1">
                  <b-media no-body>
                      <b-media-aside class="mr-2">
                          <b-avatar size="48" variant="light-primary">
                          <feather-icon size="24" icon="DollarSignIcon"/>
                          </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">                                    
                          <h4 class="font-weight-bolder mb-0">${{parseFloat(cattleOffering.priceKg).toFixed(1).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} <span style="font-size:12px">COP</span></h4>
                          <b-card-text class="font-small-3 mb-0">Precio Kilogramo</b-card-text>
                      </b-media-body>
                  </b-media>
                </b-col>                
                <b-col md="4" lg="4" sm="4" class="mb-2 mb-md-0 mb-2 mb-xl-0 mt-1">
                  <b-media no-body>
                      <b-media-aside class="mr-2">
                          <b-avatar size="48" variant="light-primary">
                          <feather-icon size="24" icon="DollarSignIcon"/>
                          </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">                                    
                          <h4 class="font-weight-bolder mb-0">${{parseFloat(cattleOffering.totalPrice).toFixed(1).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}<span style="font-size:12px">COP</span></h4>
                          <b-card-text class="font-small-3 mb-0">Precio Lote</b-card-text>
                      </b-media-body>
                  </b-media>
                </b-col>
                <b-col md="4" lg="4" sm="4" class="mb-2 mb-md-0 mb-2 mb-xl-0 mt-1">
                    <b-media no-body>
                      <b-media-aside class="mr-2">
                        <b-avatar size="48" variant="light-primary">
                          <feather-icon size="24" icon="AwardIcon"/>
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <h4 class="font-weight-bolder mb-0">{{cattleOffering.catlleOfferSex}}</h4>
                        <b-card-text class="font-small-3 mb-0">Sexo</b-card-text>
                      </b-media-body>
                    </b-media>
                </b-col>
                <b-col md="4" lg="4" sm="4" class="mb-2 mb-md-0 mb-2 mb-xl-0 mt-1">
                  <b-media no-body>
                      <b-media-aside class="mr-2">
                          <b-avatar size="48" variant="light-primary">
                          <feather-icon size="24" icon="ClipboardIcon"/>
                          </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">                                    
                          <h4 class="font-weight-bolder mb-0">{{parseFloat(cattleOffering.averageWeight).toFixed(1).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}<span style="font-size:12px">Kg</span></h4>
                          <b-card-text class="font-small-3 mb-0">Peso Promedio Animal</b-card-text>
                      </b-media-body>
                  </b-media>
                </b-col>
                <b-col md="4" lg="4" sm="4" class="mb-2 mb-md-0 mb-2 mb-xl-0 mt-1">
                  <b-media no-body>
                      <b-media-aside class="mr-2">
                          <b-avatar size="48" variant="light-primary">
                          <feather-icon size="24" icon="ClipboardIcon"/>
                          </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">                                    
                          <h4 class="font-weight-bolder mb-0">{{parseFloat(cattleOffering.totalWeightKg).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}<span style="font-size:12px">Kg</span></h4>
                          <b-card-text class="font-small-3 mb-0">Peso Lote</b-card-text>
                      </b-media-body>
                  </b-media>
                </b-col> 
            </b-row>
          </b-card-body>
        </b-card>

        <b-row>
          <b-col md="8" lg="8" xl="8">
            <b-card style="padding-bottom: 0;">
              <b-card-title class="mb-0">Detalle y Descripción del Lote de Animales</b-card-title>
                <b-card-body>
                  <b-row> 
                      <b-col xl="8" md="8" sm="8">
                          {{cattleOffering.offerDescription}}
                      </b-col> 
                  </b-row>
              </b-card-body>
            </b-card>
          </b-col>

          <b-col md="4" lg="4" xl="4">
            <b-card>
              <b-card-body class="statistics-body" style="padding:0px 21px 0px 21px">
                <b-row style="justify-content:center">                
                  <b-col md="12" lg="12" xl="12" >                    
                      <b-button @click="openWhatsapp(event)" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" target="_blank" block>
                        <feather-icon icon="MessageCircleIcon" class="mr-50"/>
                        <span class="align-middle">Quiero más información</span>
                      </b-button>
                  </b-col>
                </b-row>

                <b-row class="mt-1" style="justify-content:center">
                  <b-col  md="12" lg="12" xl="12">
                      <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="warning" v-b-modal.modal-secondary block>                                    
                          <feather-icon icon="CornerUpRightIcon" class="mr-50"/>                                      
                          Compartir
                      </b-button>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
          </b-row>
      </b-col>
    </b-row>

    <b-modal id="modal-secondary" hide-footer centered title="Compartir">
      <b-card-text> 
          <b-row>
            <b-col md="12" xl="12" class="mb-1">
                <b-form-group>                    
                  <textarea class="form-control" style="min-width: 100%" v-model="cattleOfferUrl" ref="text" readonly/>                      
                </b-form-group>
            </b-col>                                  
        </b-row>            
        <b-button class="mt-1" @click="copyLink" variant="primary" block >                
            <span class="align-middle">Copiar Link</span>
        </b-button> 
      </b-card-text>
    </b-modal>
  </div>
      
    </template>
    
    <script>
    
    import axios from '@axios'
    import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
    import {
      BCard, BCardText, BButton, BRow, BCol, BImg, BCardBody, BCardTitle, BCardSubTitle, BLink, BProgress, BProgressBar, BMedia, BAvatar, BMediaAside, BMediaBody, BEmbed, 
      BCardHeader, BCarousel, BCarouselSlide, BModal, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BAlert, BTooltip
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
    import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
    import BCardCode from '@core/components/b-card-code'
    import Cleave from 'vue-cleave-component'
    import VueApexCharts from 'vue-apexcharts'
    import { $themeColors } from '@themeConfig'
    import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
    import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
    import RisksModalComponent from '@core/components/RisksModalComponent'
    
    const $trackBgColor = '#EBEBEB'
    
    export default {
      components: {
        RisksModalComponent,
        AppCollapse,
        AppCollapseItem,
        BCard,
        BCardText,
        BButton,
        BCardBody,
        BCardTitle,
        BCardSubTitle,
        BCardHeader,
        BRow,
        BCol,
        BLink,
        BImg,
        BProgress,
        BProgressBar,
        StatisticCardHorizontal,
        StatisticCardVertical,
        BMedia,
        BAvatar,
        BMediaAside,
        BMediaBody,
        BCardCode,
        BEmbed,
        BCarousel, 
        BCarouselSlide,
        BModal,
        BFormGroup, 
        BFormInput, 
        BInputGroupAppend, 
        BInputGroup,
        Cleave,
        VueApexCharts,
        BAlert,
        BTooltip,
        
      },
      data() {
        return {
          processing: false,
          cattleOffering: {},
          amountToInvest: '0',
          amountToReceive: '0',
          simulateTotalToInvest: null,
          simulateTotalToReceive: null,
          totalGainPercent: null,
          minimalInvestment:700000,
          max: 100,
          minBar: 0,
          simulatedRevenueCowsAndBulls: 0,
          options: {
              date: {
              date: true,
              delimiter: '-',
              datePattern: ['Y', 'm', 'd'],
              },
              number: {
              numeral: true,
              numeralThousandsGroupStyle: 'thousand',
              },
          },  
          cattleOfferUrl: window.location.href,
          isBreedingProject: false,
          cattleOfferCode: null
        }
      },
      created() {
        if(this.$route != null && this.$route.query != null && this.$route.query.cattleOfferCode != null) {
            this.cattleOfferCode = this.$route.query.cattleOfferCode;

            this.getCattleOfferingPublicByCode();
        } 
      },
      watch:{    
        simulateTotalToInvest: function(val) { this.simulateTotalToInvest = val; },
        minBar: function(val) { this.minBar = val; },
      },
      methods: {

        getCattleOfferingPublicByCode() {          
            this.processing = true;
            axios.get('/CattleOffering/GetCattleOfferingByCattleOfferingCode', { params: { cattleOfferCode: this.cattleOfferCode }})        
            .then(response => {
              this.processing = false;              
              this.cattleOffering = response.data;              

              this.cattleOffering.averageWeight = (this.cattleOffering.totalWeightKg)/ (this.cattleOffering.amountOfAnimals)              

              if(this.cattleOffering.averageWeight == null || this.cattleOffering.averageWeight == undefined){
                  this.cattleOffering.averageWeight = 0
              }
              if(this.cattleOffering.averageWeightKg == null || this.cattleOffering.averageWeightKg == undefined){
                this.cattleOffering.averageWeightKg = 0
              }
              if(this.cattleOffering.priceKg == null){
                this.cattleOffering.priceKg = 0
              }
              if(this.cattleOffering.breed == null){
                this.cattleOffering.breed = '--'
              }
              if(this.cattleOffering.category == null){
                this.cattleOffering.category = '--'
              }
              if(this.cattleOffering.totalWeightKg == null || this.cattleOffering.totalWeightKg == undefined){
                this.cattleOffering.totalWeightKg = 0
              }
            })
            .catch(error => {
              this.processing = false;
               
            })
        },
        copyLink() {
            this.$refs.text.select();
            document.execCommand('copy');
        },
        openWhatsapp() {
          var code = this.cattleOffering.cattleOfferCode         
          var url = "https://wa.me/573143566738?text=Hola,+quiero+más+información+del+lote" + " " + code
          window.open(url, '_blank', 'noreferrer');
        },
      },
      directives: {
        Ripple,
      },
      setup() {
        const openPorjectLocationInMap = iconName => {      
          window.open("https://www.google.com/maps/@4.6951311,-74.0616453,15z");
        }
        return {
          openPorjectLocationInMap,
        }
      },

    }
    </script>       
    